@font-face {
  font-family:"Roboto-Light";
  src: url("./fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family:"Roboto-Regular";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family:"Roboto-Medium";
  src: url("./fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family:"Roboto-Bold";
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 2.5rem;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}